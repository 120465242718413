import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import axios from 'axios';
import RiddleInput from "./RiddleInput";
import RiddleBtn from "./RiddleBtn";
type Props = {
    nextStage: (token: string) => void
}

const RiddleStageOne: React.FC<Props> = ({ nextStage }) => {
  const [value, setValue] = useState('')
  const [wrongPhrase, setWrongPhrase] = useState(false)

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            backendUrl
          }
        }
      }
    `
  )

  const decryptMessage = async () => {
    try {
      const {data} = await axios.post<{token: string}>(`${site.siteMetadata.backendUrl}/events/riddle/p1`, {
          value
      })
  
      nextStage(data.token)
    } catch (e) {
      setWrongPhrase(true);
      setTimeout(() => {
        setWrongPhrase(false);
      }, 300)
    }
  }

  return (
    <section className="w-full h-full flex justify-center items-center gap-2 max-w-[700px] mx-auto">
      <RiddleInput value={value} setValue={setValue} shouldShake={wrongPhrase}/>
      <RiddleBtn cta={decryptMessage} text="Decrypt"/>
    </section>
  )
}

export default RiddleStageOne
