import React from "react"

type Props = {
    text: string,
    cta: () => void
}

const RiddleBtn: React.FC<Props> = ({ text, cta }) => {

  return (
    <button 
        className="bg-transparent border-white border-2 rounded-full text-white px-2 py-1 uppercase hover:bg-white hover:text-black transition-all duration-150" 
        onClick={cta}
    >
      {text}
    </button>
  )
}



export default RiddleBtn
