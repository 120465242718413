import React from "react"

type Props = {
    value: string,
    setValue: (value: string) => void
    shouldShake: boolean
}

const RiddleInput: React.FC<Props> = ({ value, setValue, shouldShake }) => {

  return (
    <input 
        type="text" 
        className={`${shouldShake ? "shake pointer-events-none": ''} bg-transparent border-white rounded-md text-white w-full`} 
        value={value} 
        onChange={(e) => setValue(e.target.value)}
    />
  )
}



export default RiddleInput
