import React from "react"
import { SecretData } from "./RiddleView"
import QRCode from "react-qr-code";

type Props = {
    secretData: SecretData
}

const RiddleSuccess: React.FC<Props> = ({ secretData }) => {
  return (
    <section className="w-full h-full flex flex-col justify-center items-center gap-4 px-2">
        <div className="max-w-[500px] max-h-[500px] relative p-4 bg-white">
          <QRCode
              size={512}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={secretData.location}
              viewBox="0 0 256 256"
          />
        </div>
          <span>{secretData.text} </span>
          <span>Wait for further instructions.</span>
    </section>
  )
}

export default RiddleSuccess
