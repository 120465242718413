import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RiddleView from "../components/riddle/RiddleView"

const RiddlePage: React.FC = () => {
  return (
    <Layout bgColor="black">
      <SEO title="Riddle" />

      <div className="relative flex min-h-screen flex-col items-center justify-center">
        <RiddleView />
      </div>
    </Layout>
  )
}

export default RiddlePage
