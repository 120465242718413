import React, { useState } from "react"
import RiddleStageOne from "./RiddleStageOne"
import RiddleStageTwo from "./RiddleStageTwo"
import RiddleSuccess from "./RiddleSuccess"
import { API_EVENTS } from "../../events"
import EventControllerBtn from "../EventControllerBtn"
import { useRecoilState } from "recoil"
import { playMusic } from "../../state/music-player"

export type SecretData = {
    location: string,
    text: string
}

const RiddleView: React.FC = () => {
  const [riddleStage, setRiddleStage] = useState(0);
  const [token, setToken] = useState('');
  const [_, setPlay] = useRecoilState(playMusic)
  const [secretData, setSecretData] = useState<SecretData>();

  return (
    <section className="!font-suisse-intl w-full relative">
        <div className="absolute z-10 top-0 left-0 right-0 bottom-0 flex justify-center items-center pointer-events-none">
            <EventControllerBtn
                eventName={API_EVENTS.RIDDLE}
                eventEndedText="Event Ended"
                onEventEnded={() => setRiddleStage(-1)}
                eventEndedStyles="text-black bg-white"
                endEventBtnOnly
            />
        </div>
        {riddleStage === 0 && <RiddleStageOne 
            nextStage={(t) => {setRiddleStage(s => s + 1); setToken(t); setPlay(true)}} 
        />}
        {riddleStage === 1 && <RiddleStageTwo 
            nextStage={(data) => {
                setRiddleStage(s => s + 1);
                setSecretData(data)
            }} 
            token={token}
        />}
        {riddleStage === 2 && secretData && <RiddleSuccess secretData={secretData}/>}
    </section>
  )
}

export default RiddleView
