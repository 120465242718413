import React, { useState } from "react"
import axios from 'axios';
import { graphql, useStaticQuery } from "gatsby";
import { SecretData } from "./RiddleView";
import RiddleBtn from "./RiddleBtn";
import RiddleInput from "./RiddleInput";

type Props = {
    nextStage: (data: SecretData) => void
    token: string
}

const RiddleStageTwo: React.FC<Props> = ({ nextStage, token }) => {
    const [value, setValue] = useState('')
    const [wrongPhrase, setWrongPhrase] = useState(false)

    const { site } = useStaticQuery(
      graphql`
        {
          site {
            siteMetadata {
              backendUrl
            }
          }
        }
      `
    )
  
    const decryptMessage = async () => {
        try {
            const { data } = await axios.post<SecretData>(`${site.siteMetadata.backendUrl}/events/riddle/p2`, {
                value
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
    
            nextStage(data)
          } catch (e) {
            setWrongPhrase(true);
            setTimeout(() => {
              setWrongPhrase(false);
            }, 300)
          }
    }

    if(!token) {
      return <></>
    }
  
    return (
        <section className="w-full h-full flex flex-col justify-center gap-4 max-w-[700px] mx-auto px-2 py-12 md:py-24">
            <div>
                        <p>“At times I forget…I forget what it’s like…<br/>
                And to think that back then it was everything I knew… It took 35 years…<br/>
                Just a guy floating, hanging around chasing the bag, no matter what it held inside…<br/>
                And you know what’s funny, Seiko?<br/>
                With each setting sun, you’ll forget too…<br/>
                You’ll forget what it’s like to float, to hang to what&apos;s dear.<br/>
                To have your will emboldened by the dream, to have endless fuel to add to the fire.<br/>
                You’ll forget but don’t sweat it.<br/>
                It’s a good thing, a necessary step, one that we take seriously and with pride.”</p>

                <p className="py-4">“But… How? What’s good about forgetting our roots, Captain?”</p>

                <p>“Endless possibility, unlimited potential”.</p>

                <p>“Seiko, does the Sun change every time it sets?<br/>
                “No, Master.”<br/>
                “And how do you know? What&apos;s 1 day for the Sun?”<br/>
                “...”<br/>
                “You don’t. We like to pretend that it’s always the same, but it has changed. ”<br/>
                “It does not mean that its light was dimmed, it&apos;s not colder nor more distant. Just different.”<br/>
                “The Sun forgot yesterday, 3 times over. It lives in the present, it’s the only thing it can do.”<br/>
                “And, Well, Seiko… you’ll learn to forget, you’ll forget but if I taught you well, you’ll be like the Sun”<br/>
                “Always changing?”<br/>
                “Ever-changing but always present.”<br/>
                “I think I’d like that…”</p>
                
                <p className="pt-4">Seiko’s words were stifled by a faint, rhythmic sound that was starting to emerge out of nowhere. <br/>
                From the lower levels of the Holy Pagoda, emanating upwards, a vibration was starting to conquer every surface. Echoing and trembling, the marbled walls were shaken by the sound of the ceremonial drums. </p>
                
                <p className="pt-4">“It’s starting…”<br/>
                “Yes Master, we shall go…”<br/>
                “Not yet, I need to discuss something with you, Seiko.”<br/>
                “Yes Master”<br/>
                “Find him. Find the man who lives in the past. Find it for me.”</p>
                
                <p className="pt-4">Seiko’s eyes grew 9 sizes, like a candid full moon trying to fall out of the sky.<br/>
                In a panic, his heartbeat surpassed the incessant booming clamour in the air. He then solemnly said: </p>
                
                <p className="pt-4">“I Will.”</p>
            </div>
            <div className="flex justify-center gap-2 w-full pt-4">
                <RiddleInput value={value} setValue={setValue} shouldShake={wrongPhrase}/>
                <RiddleBtn cta={decryptMessage} text="Enter"/>
            </div>
        </section>
    )
}

export default RiddleStageTwo
